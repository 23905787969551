import React from 'react';
import Photos from './Photos';
import Banner from './Banner';
import Words from './Words';
import { placesImages } from './images.js';
import { placesWords } from './copy.js';

export default function Places() {
  return (
    <div>
      <Banner />
      <Words words={ placesWords }/>
      <Photos images={ placesImages } />
    </div>
  )}
