import React from "react";
import { Link } from "react-router-dom";

const cont = {
  backgroundColor: "#eee",
  cursor: "pointer",
  overflow: "hidden",
  position: "relative",
};

const contNoClick = {
  backgroundColor: "#eee",
  overflow: "hidden",
  position: "relative",
};

const SelectedImage = ({
  index,
  photo,
  margin,
  direction,
  top,
  left,
  category,
  showOverlay,
}) => {
  if (direction === "column") {
    cont.position = "absolute";
    cont.left = left;
    cont.top = top;
  }
  if (showOverlay) {
    return (
      <div
        style={{ margin, height: photo.height, width: photo.width, ...cont }}
        className={"container"}
      >
        <Link to={category}>
          <img alt={photo.type} {...photo} />
          <div className="overlay">
            <div className="text">{category}</div>
          </div>
        </Link>
      </div>
    );
  }
  return (
    <div
      style={{
        margin,
        height: photo.height,
        width: photo.width,
        ...contNoClick,
      }}
      className={"container"}
    >
      <img alt={photo.type} {...photo} />
    </div>
  );
};

export default SelectedImage;
