export const contactImages = [
  {
    src: "./images/Site/lavenderstemswm.jpg",
    width: 2,
    height: 3,
  },
];

export const celebrationImages = [
  {
    src: "./images/Celebrations/backedit.jpg",
    width: 30,
    height: 46,
  },
  {
    src: "./images/Celebrations/bridgepoloshirt3.jpg",
    width: 30,
    height: 46,
  },
  {
    src: "./images/Celebrations/shoesBW.jpg",
    width: 68,
    height: 102,
  },
  {
    src: "./images/Celebrations/torah4.jpg",
    width: 51,
    height: 34,
  },
  {
    src: "./images/Celebrations/greenhallwayverticalarmsraised.jpg",
    width: 34,
    height: 51,
  },
  {
    src: "./images/Celebrations/hands.jpg",
    width: 700,
    height: 1000,
  },
  {
    src: "./images/Celebrations/prayerhandsrosaryprofileBWedit.jpg",
    width: 68,
    height: 102,
  },
  {
    src: "./images/Celebrations/firstcommunion2.jpg",
    width: 30,
    height: 46,
  },
  {
    src: "./images/Celebrations/familyhorizontal.jpg",
    width: 102,
    height: 68,
  },
  {
    src: "./images/Celebrations/trail4.jpg",
    width: 30,
    height: 46,
  },
  {
    src: "./images/Celebrations/rosary.jpg",
    width: 68,
    height: 102,
  },
  {
    src: "./images/Celebrations/bridgeverticalBW.jpg",
    width: 68,
    height: 102,
  },
  {
    src: "./images/Celebrations/elizabethsenior.jpg",
    width: 28,
    height: 35,
  },
  {
    src: "./images/Celebrations/profile.jpg",
    width: 68,
    height: 102,
  },
  {
    src: "./images/Celebrations/archwayseated.jpg",
    width: 30,
    height: 46,
  },
  {
    src: "./images/Celebrations/prayerhands2BW.jpg",
    width: 65,
    height: 81,
  },
  {
    src: "./images/Celebrations/prepshirt1.jpg",
    width: 30,
    height: 46,
  },
  {
    src: "./images/Celebrations/rosary3.jpg",
    width: 30,
    height: 46,
  },
  {
    src: "./images/Celebrations/southstreetmosaicalley.jpg",
    width: 30,
    height: 46,
  },
  {
    src: "./images/Celebrations/torah1.jpg",
    width: 51,
    height: 34,
  },
  {
    src: "./images/Celebrations/norasenior1.jpg",
    width: 17,
    height: 24,
  },
  {
    src: "./images/Celebrations/facingcameraedit.jpg",
    width: 68,
    height: 102,
  },
  {
    src: "./images/Celebrations/elizabethsenior1.jpg",
    width: 18,
    height: 25,
  },
  {
    src: "./images/Celebrations/southstreetmosaicgarage4.jpg",
    width: 51,
    height: 34,
  },
  {
    src: "./images/Celebrations/firstcommunion.jpg",
    width: 30,
    height: 46,
  },
  {
    src: "./images/Celebrations/firstcommunion1.jpg",
    width: 30,
    height: 46,
  },
  {
    src: "./images/Celebrations/profilelookingupedit.jpg",
    width: 102,
    height: 68,
  },
  {
    src: "./images/Celebrations/headshot.jpg",
    width: 17,
    height: 23,
  },
  {
    src: "./images/Celebrations/norasenior.jpg",
    width: 14,
    height: 19,
  },
  {
    src: "./images/Celebrations/milliereading.jpg",
    width: 17,
    height: 26,
  },
];

export const placesImages = [
  {
    src: "./images/Places/plantlinesWM.jpg",
    width: 24,
    height: 30,
  },
  {
    src: "./images/Places/aixmarket2IGWM.jpg",
    width: 42,
    height: 34,
  },
  {
    src: "./images/Places/cantinasceneWM.jpg",
    width: 34,
    height: 51,
  },
  {
    src: "./images/Places/centrostoricosucculentdarkWM.jpg",
    width: 10,
    height: 8,
  },
  {
    src: "./images/Places/florencebikeWM.jpg",
    width: 34,
    height: 51,
  },
  {
    src: "./images/Places/florencemanonbicyclewm.jpg",
    width: 28,
    height: 42,
  },
  {
    src: "./images/Places/gallipolibasketsIGWM.jpg",
    width: 34,
    height: 43,
  },
  {
    src: "./images/Places/gallipolibeachedit.jpg",
    width: 51,
    height: 34,
  },
  {
    src: "./images/Places/ladysweepingWM.jpg",
    width: 34,
    height: 50,
  },
  {
    src: "./images/Places/gallipolibluedoorIGWM.jpg",
    width: 36,
    height: 28,
  },
  {
    src: "./images/Places/genovaceilingWM.jpg",
    width: 33,
    height: 50,
  },
  {
    src: "./images/Places/ladywithbasketWM.jpg",
    width: 34,
    height: 45,
  },
  {
    src: "./images/Places/leavesBWIGWM.jpg",
    width: 34,
    height: 43,
  },
  {
    src: "./images/Places/locorotondopotteryIGWM.jpg",
    width: 32,
    height: 41,
  },
  {
    src: "./images/Places/maltamarsaxlokkboatIGWM.jpg",
    width: 34,
    height: 43,
  },
  {
    src: "./images/Places/palmlinesWM.jpg",
    width: 34,
    height: 51,
  },
  {
    src: "./images/Places/palmshadowsBWIGWM.jpg",
    width: 37,
    height: 30,
  },
  {
    src: "./images/Places/peschieradelgardabuildingIGWM.jpg",
    width: 43,
    height: 34,
  },
  {
    src: "./images/Places/teramolady1WM.jpg",
    width: 34,
    height: 43,
  },
  {
    src: "./images/Places/sunrisepalmsdarkWM.jpg",
    width: 34,
    height: 51,
  },
  {
    src: "./images/Places/trulliWM.jpg",
    width: 34,
    height: 51,
  },
  {
    src: "./images/Places/trabocco3WM.jpg",
    width: 41,
    height: 27,
  },
];

export const familyImages = [
  {
    src: "./images/Families/longwoodmariowater2WM.jpg",
    width: 51,
    height: 33,
  },
  {
    src: "./images/Families/allysonleavescrop.jpg",
    width: 23,
    height: 35,
  },
  {
    src: "./images/Families/dunesfamilysittingBW.jpg",
    width: 49,
    height: 32,
  },
  {
    src: "./images/Families/familytreesvertical.jpg",
    width: 23,
    height: 35,
  },
  {
    src: "./images/Families/familysteps1.jpg",
    width: 47,
    height: 31,
  },
  {
    src: "./images/Families/ikidshands.jpg",
    width: 29,
    height: 44,
  },
  {
    src: "./images/Families/ltreesfamilyfarawayhorizontalBW.jpg",
    width: 49,
    height: 32,
  },
  {
    src: "./images/Families/burritt.jpg",
    width: 13,
    height: 16,
  },
  {
    src: "./images/Families/anna2.jpg",
    width: 47,
    height: 31,
  },
  {
    src: "./images/Families/sydneyandthepond.jpg",
    width: 23,
    height: 35,
  },
  {
    src: "./images/Families/bridgefamily3.jpg",
    width: 51,
    height: 33,
  },
  {
    src: "./images/Families/fjustinwalkingaway.jpg",
    width: 23,
    height: 35,
  },
  {
    src: "./images/Families/barnfamily.jpg",
    width: 23,
    height: 35,
  },
  {
    src: "./images/Families/nmomandson1.jpg",
    width: 51,
    height: 33,
  },
  {
    src: "./images/Families/mommybilly2.jpg",
    width: 23,
    height: 35,
  },
  {
    src: "./images/Families/imarklukeprofiles.jpg",
    width: 51,
    height: 34,
  },
  {
    src: "./images/Families/sherretta.jpg",
    width: 23,
    height: 35,
  },
  {
    src: "./images/Families/sullivan.jpg",
    width: 28,
    height: 18,
  },
  {
    src: "./images/Families/sheehan.jpg",
    width: 23,
    height: 35,
  },
  {
    src: "./images/Families/carr.jpg",
    width: 28,
    height: 35,
  },
  {
    src: "./images/Families/leavesfamily2.jpeg",
    width: 51,
    height: 34,
  },
  {
    src: "./images/Families/bridgekidsnash.jpg",
    width: 23,
    height: 35,
  },
  {
    src: "./images/Families/burrittsisters.jpg",
    width: 23,
    height: 35,
  },
  {
    src: "./images/Families/sherrettaBW.jpg",
    width: 51,
    height: 33,
  },
  {
    src: "./images/Families/smith.jpg",
    width: 23,
    height: 35,
  },
];

export const mainImages = [
  {
    src: "./images/Celebrations/backedit.jpg",
    width: 15,
    height: 23,
  },
  {
    src: "./images/Families/familytreesvertical.jpg",
    width: 7,
    height: 10,
  },
  {
    src: "./images/Places/florencebikeWM.jpg",
    width: 34,
    height: 51,
  },
  {
    src: "./images/Celebrations/crossedit.jpg",
    width: 34,
    height: 51,
  },
  {
    src: "./images/Families/sydneyandthepond.jpg",
    width: 29,
    height: 44,
  },
  {
    src: "./images/Places/centrostoricosucculentdarkWM.jpg",
    width: 10,
    height: 8,
  },
  {
    src: "./images/Celebrations/rosary.jpg",
    width: 34,
    height: 51,
  },
  {
    src: "./images/Families/dunesfamilysittingBW.jpg",
    width: 48,
    height: 31,
  },
  {
    src: "./images/Places/cantinasceneWM.jpg",
    width: 34,
    height: 51,
  },
  {
    src: "./images/Families/ltreesfamilyfarawayhorizontalBW.jpg",
    width: 49,
    height: 32,
  },
  {
    src: "./images/Celebrations/torah1.jpg",
    width: 51,
    height: 34,
  },
  {
    src: "./images/Celebrations/bridgeverticalBW.jpg",
    width: 15,
    height: 23,
  },
];
