import React from 'react';
import Photos from './Photos';
import Banner from './Banner';
import Words from './Words';
import { celebrationImages } from './images.js';
import { celebrationWords } from './copy.js';

export default function Celebrations() {
  return (
    <div>
      <Banner />
      <Words words={ celebrationWords } />
      <Photos images={ celebrationImages } />
    </div>
  )}
