import React from 'react';
import Photos from './Photos';
import Banner from './Banner';
import Words from './Words';
import { mainImages } from './images.js';
import { contactWords } from './copy.js';

export default function Home() {
  return (
    <div>
      <Banner />
      <Words words={contactWords} route="/contact" />
      <Photos images={mainImages} showOverlay={true} />
    </div>
  )
}
