import React from 'react';
import Banner from './Banner';
import Words from './Words';
import { contactCopy } from './copy.js';

export default function Contact() {
  return (
    <div>
      <Banner />
      <div>
        <br></br>
        <div>
          <a href="mailto:lisapalumbi@gmail.com">lisapalumbi at gmail</a>
        </div>
        <a href="https://www.instagram.com/lisapalumbiphotography/"><img src="images/Site/Instagram.png" alt="insta" height="45px" /></a>
      </div>
      <Words words={contactCopy} />
      <img src="/images/Site/lavenderstemswm.jpg" width="25%" alt="cont" />
    </div>
  )
}
