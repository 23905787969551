import React from 'react';
import Photos from './Photos';
import Banner from './Banner';
import Words from './Words';
import { familyImages } from './images.js';
import { familyWords } from './copy.js';

export default function Families() {
  return (
    <div>
      <Banner />
      <Words words={ familyWords } />
      <Photos images={ familyImages } />
    </div>
  )}
