import React from 'react';
import { Link } from "react-router-dom";

export default function Words(props) {
  return (
    <Link to={props.route ?? "/"}>
      <div className="copy">
        <hr className="horizontal" />
        <h4>{props.words}</h4>
        <hr className="horizontal" />
      </div>
    </Link>
  );
}