import React from 'react';
import { Link } from "react-router-dom";

export default function Banner() {
  return (
    <Link to="/">
      <h1>lisa d palumbi photography</h1>
      <h3>there is beauty to be captured</h3>
    </Link>
  );
}