import React, { useCallback } from "react";
import Gallery from 'react-photo-gallery';
import SelectedImage from "./SelectedImage";

export default function Photos(props) {

  const imageRenderer = useCallback(
    ({ index, left, top, key, photo }) => (
      <SelectedImage
        key={key}
        margin={"4px"}
        index={index}
        photo={photo}
        left={left}
        top={top}
        category={photo.src.split("/")[2]}
        showOverlay={props.showOverlay}
      />
    ),
    [props.showOverlay]
  );

  return (
    <div style={{ width: '100vw' }}>
      <Gallery
        photos={props.images}
        columns={5}
        margin={8}
        renderImage={imageRenderer}
      />
    </div>
  );
}